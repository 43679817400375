export const MenuItems = [
    {
        title: 'Projects',
        path: 'projects',
        cName: 'dropdown-link'
    },
    {
        title: 'Monsters Rolodex',
        path: 'monsters-rolodex',
        cName: 'dropdown-link'
    },
    {
        title: 'Shop',
        path: 'shop',
        cName: 'dropdown-link'
    }
]